import Layout from "../layout/layout"
import SEO from "../components/seo"
import React, { useEffect } from "react"
import ContactUsForm from "../components/ContactUsForm"
import { AnalyticsEventCategories, emitAnalyticsEvent } from "../analytics"

const ContactUs = () => {
    useEffect(() => {
        emitAnalyticsEvent("Page view", AnalyticsEventCategories.CONTACT_US)
    }, [])

    return (
        <Layout>
            <SEO title="Contact us" />
            <ContactUsForm />
        </Layout>
    )
}
export default ContactUs
